import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
// import Pagination from "../components/Blog/Pagination";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";
import BlogPage from "../components/Blog/BlogPage";

const BlogTags = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const tags = data.tagsGroup.distinct;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout darkMenu={true}>
      <Seo
        title={
          pageContext.tag +
          meta.sitePageTitleSep +
          meta.blogName +
          (pageContext.pageNumber > 0
            ? meta.sitePageTitleSep +
              "Page " +
              (pageContext.pageNumber + 1) +
              " of " +
              pageContext.numberOfPages
            : "") +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + "/tag/" + pageContext.tag}
        description={meta.blogDescription}
        image={meta.siteUrl + meta.siteImage}
        twitterTitle={meta.siteTitle + " " + meta.blogName}
        facebookTitle={meta.siteTitle + " " + meta.blogName}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <BlogPage
        title={[meta.blogName, pageContext.tag]}
        showAll={true}
        categoryLinkText={"Categories"}
        posts={posts}
        tags={tags}
        pageId={"blog-page" + pageContext.pageNumber}
        currentTag={pageContext.tag}
        // inTagPage={true}
      />
      {/* <Pagination pageContext={pageContext} pathPrefix="/journal" /> */}
    </Layout>
  );
};

export default BlogTags;

export const blogTagsQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { eq: $tag } } }
      sort: {
        order: [ASC, DESC]
        fields: [frontmatter___catOrder, frontmatter___date]
      }
    ) {
      totalCount
      edges {
        node {
          timeToRead
          frontmatter {
            title
            catOrder
            tags
            path
            date(formatString: "MMMM DD, YYYY")
            excerpt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    tagsGroup: allMarkdownRemark {
      distinct(field: frontmatter___tags)
    }
  }
`;
